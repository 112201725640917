import request from "@/core/services/axios";
export function overallanalyExport(params) {
  // 总体分析-总体情况分析-导出
  return request({
    url: `/report/unionoverallanaly/overallanaly/export`,
    method: "get",
    params: params,
  });
}
export function studentlisttotallevelExport(params) {
  // 总体分析-总分-等级成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlisttotallevel/export`,
    method: "post",
    data: params,
  });
}
export function studentlistsubjectlevelExport(params) {
  // 总体分析-单科-等级成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlistsubjectlevel/export`,
    method: "post",
    data: params,
  });
}
export function gradelevellistExport(params) {
  // 学校分析-年级等级榜
  return request({
    url: `/report/unionschoolanaly/gradelevellist/export`,
    method: "post",
    data: params,
  });
}
export function areaoverallanalyExport(params) {
  // 总体分析-各地区总体情况分析-导出
  return request({
    url: `/report/unionoverallanaly/areaoverallanaly/export`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistributionExport(params) {
  // 总体分析-总分分数段分布-总分分数段分布表-导出
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistribution/export`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistributionpicExport(params) {
  // 总体分析-总分分数段分布-总分分数段分布图-导出
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistributionpic/export`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistributiondetailsExport(params) {
  // 总体分析-总分分数段分布-总分详细分数段分布表-导出
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistributiondetails/export`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistributionExport(params) {
  // 总体分析-单科分数段分布-单科分数段分布表-导出
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistribution/export`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistributionpicExport(params) {
  // 总体分析-单科分数段分布-单科分数段分布图-导出
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistributionpic/export`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistributiondetailsExport(params) {
  // 总体分析-单科分数段分布-单科详细分数段分布表-导出
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistributiondetails/export`,
    method: "get",
    params: params,
  });
}

export function studentlisttotalExport(params) {
  // 总体分析-总分-成绩榜-导出
  return request({
    url: `/report/unionoverallanaly/studentlisttotal/export`,
    method: "post",
    data: params,
  });
}
export function studentlisttotaldetailsExport(params) {
  // 总体分析-总分-详细成绩榜-导出
  return request({
    url: `/report/unionoverallanaly/studentlisttotaldetails/export`,
    method: "post",
    data: params,
  });
}
export function studentlistsubjectExport(params) {
  // 总体分析-单科-成绩榜-导出
  return request({
    url: `/report/unionoverallanaly/studentlistsubject/export`,
    method: "post",
    data: params,
  });
}
export function studentlistsubjectdetailsExport(params) {
  // 总体分析-单科-详细成绩榜-导出
  return request({
    url: `/report/unionoverallanaly/studentlistsubjectdetails/export`,
    method: "post",
    data: params,
  });
}
export function totalindicatorcompareExport(params) {
  // 各校成绩对比-总分各项指标对比-总分各项指标对比-导出
  return request({
    url: `/report/unionschoolcontrast/totalindicatorcompare/export`,
    method: "get",
    params: params,
  });
}
export function totalindicatorcomparepicExport(params) {
  // 各校成绩对比-总分各项指标对比-总分多维指标对比图-导出
  return request({
    url: `/report/unionschoolcontrast/totalindicatorcomparepic/export`,
    method: "get",
    params: params,
  });
}
export function subjectindicatorcompareExport(params) {
  // 各校成绩对比-单科各项指标对比-单科各项指标对比-导出
  return request({
    url: `/report/unionschoolcontrast/subjectindicatorcompare/export`,
    method: "get",
    params: params,
  });
}
export function subjectindicatorcomparepicExport(params) {
  // 各校成绩对比-单科各项指标对比-单科多维指标对比图-导出
  return request({
    url: `/report/unionschoolcontrast/subjectindicatorcomparepic/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparesummaryExport(params) {
  // 各校成绩对比-各项指标对比汇总-对比汇总-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparesummary/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcompareaveragescoreExport(params) {
  // 各校成绩对比-各项指标对比汇总-平均分-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcompareaveragescore/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparescorerateExport(params) {
  // 各校成绩对比-各项指标对比汇总-得分率-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparescorerate/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparestandarddeviationExport(params) {
  // 各校成绩对比-各项指标对比汇总-标准差-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparestandarddeviation/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparemedianExport(params) {
  // 各校成绩对比-各项指标对比汇总-中位数-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparemedian/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparehighestscoreExport(params) {
  // 各校成绩对比-各项指标对比汇总-最高分-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparehighestscore/export`,
    method: "get",
    params: params,
  });
}
export function indicatorcompareratioExport(params) {
  // 各校成绩对比-各项指标对比汇总-比率-导出
  return request({
    url: `/report/unionschoolcontrast/indicatorcompareratio/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcompareExport(params) {
  // 各校成绩对比-班级各项指标对比-导出
  return request({
    url: `/report/unionschoolcontrast/classindicatorcompare/export`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentcompareExport(params) {
  // 各校成绩对比-总分分数段对比-总分分数段对比-导出
  return request({
    url: `/report/unionschoolcontrast/totalscoresegmentcompare/export`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentcomparedetailsExport(params) {
  // 各校成绩对比-总分分数段对比-总分详细分数段对比-导出
  return request({
    url: `/report/unionschoolcontrast/totalscoresegmentcomparedetails/export`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentcompareExport(params) {
  // 各校成绩对比-单科分数段对比-单科分数段对比-导出
  return request({
    url: `/report/unionschoolcontrast/subjectscoresegmentcompare/export`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentcomparedetailsExport(params) {
  // 各校成绩对比-单科分数段对比-单科详细分数段对比-导出
  return request({
    url: `/report/unionschoolcontrast/subjectscoresegmentcomparedetails/export`,
    method: "get",
    params: params,
  });
}
export function totaltopsectioncompareExport(params) {
  // 各校成绩对比-总分前N名对比-前N名对比-导出
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncompare/export`,
    method: "get",
    params: params,
  });
}
export function totaltopsectioncomparepicExport(params) {
  // 各校成绩对比-总分前N名对比-前N名对比图-导出
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncomparepic/export`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncompareExport(params) {
  // 各校成绩对比-单科前N名对比-前N名对比-导出
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncompare/export`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncomparepicExport(params) {
  // 各校成绩对比-单科前N名对比-前N名对比图-导出
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncomparepic/export`,
    method: "get",
    params: params,
  });
}
export function subjectqualitycompareExport(params) {
  // 各校成绩对比-学科质量对比分析-导出
  return request({
    url: `/report/unionschoolcontrast/subjectqualitycompare/export`,
    method: "get",
    params: params,
  });
}
export function subjectoverallevaluationExport(params) {
  // 各校成绩对比-学科总体评价-导出
  return request({
    url: `/report/unionschoolcontrast/subjectoverallevaluation/export`,
    method: "get",
    params: params,
  });
}

export function totaltopsectioncomparesingletopsectionExport(params) {
  // 各校成绩对比-总分前N名对比-前N名对比-单个前N名学生数据-导出
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncomparesingletopsection/export`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncomparesingletopsectionExport(params) {
  // 各校成绩对比-单科前N名对比-前N名对比-单个前N名学生数据-导出
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncomparesingletopsection/export`,
    method: "get",
    params: params,
  });
}
export function questionanalysisExport(params) {
  // 试题分析-试题数据分析-导出
  return request({
    url: `/report/unionquestionanaly/questionanalysis/export`,
    method: "get",
    params: params,
  });
}
export function choicequestionanalysisExport(params) {
  // 试题分析-试题数据分析-导出
  return request({
    url: `/report/unionquestionanaly/choicequestionanalysis/export`,
    method: "get",
    params: params,
  });
}
export function nonchoicequestionanalysisExport(params) {
  // 试题分析-非选择题数据分析-导出
  return request({
    url: `/report/unionquestionanaly/nonchoicequestionanalysis/export`,
    method: "get",
    params: params,
  });
}
export function choicequestionansweranalysisExport(params) {
  // 试题分析-选做题在作答分析-导出
  return request({
    url: `/report/unionquestionanaly/choicequestionansweranalysis/export`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcompareaveragescoreExport(params) {
  // 试题分析-常用指标各校对比-平均分-导出
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcompareaveragescore/export`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparescorerateExport(params) {
  // 试题分析-常用指标各校对比-得分率-导出
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparescorerate/export`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparecountrightExport(params) {
  // 试题分析-常用指标各校对比-满分人数-导出
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparecountright/export`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparecountanswerExport(params) {
  // 试题分析-常用指标各校对比-答题人数-导出
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparecountanswer/export`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparechoicerateExport(params) {
  // 试题分析-常用指标各校对比-选题率-导出
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparechoicerate/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcompareaveragescoreExport(params) {
  // 试题分析-常用指标班级对比-平均分-导出
  return request({
    url: `/report/unionquestionanaly/classindicatorcompareaveragescore/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparescorerateExport(params) {
  // 试题分析-常用指标班级对比-得分率-导出
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparescorerate/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparecountrightExport(params) {
  // 试题分析-常用指标班级对比-满分人数-导出
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparecountright/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparecountanswerExport(params) {
  // 试题分析-常用指标班级对比-答题人数-导出
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparecountanswer/export`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparechoicerateExport(params) {
  // 试题分析-常用指标班级对比-选题率-导出
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparechoicerate/export`,
    method: "get",
    params: params,
  });
}
export function questionscoredistributionExport(params) {
  // 试题分析-试题得分详细分布-导出
  return request({
    url: `/report/unionquestionanaly/questionscoredistribution/export`,
    method: "get",
    params: params,
  });
}
export function paperreliabilityExport(params) {
  // 试卷命题分析-试卷信度统计-导出
  return request({
    url: `/report/unionpaperanaly/paperreliability/export`,
    method: "get",
    params: params,
  });
}
export function questionsummaryanalysisExport(params) {
  // 试卷命题分析-试卷总汇分析-导出
  return request({
    url: `/report/unionpaperanaly/questionsummaryanalysis/export`,
    method: "get",
    params: params,
  });
}
export function difficultydistributionExport(params) {
  // 试卷命题分析-试卷汇总分析-难度分布-导出
  return request({
    url: `/report/unionpaperanaly/difficultydistribution/export`,
    method: "get",
    params: params,
  });
}
export function discriminationdistributionExport(params) {
  // 试卷命题分析-试卷汇总分析-区分度分布-导出
  return request({
    url: `/report/unionpaperanaly/discriminationdistribution/export`,
    method: "get",
    params: params,
  });
}
export function difficultyleveldistributionExport(params) {
  // 试卷命题分析-试卷汇总分析-难度分布-导出
  return request({
    url: `/report/unionpaperanaly/difficultyleveldistribution/export`,
    method: "get",
    params: params,
  });
}
export function discriminationleveldistributionExport(params) {
  // 试卷命题分析-区分度等级分布-导出
  return request({
    url: `/report/unionpaperanaly/discriminationleveldistribution/export`,
    method: "get",
    params: params,
  });
}
export function typicalpaperExport(params) {
  // 特征试卷-典型试卷-导出
  return request({
    url: `/report/unionfeaturepaper/typicalpaper/export`,
    method: "get",
    params: params,
  });
}
export function subjectquestionlistExport(params) {
  // 获取科目试题列表-导出
  return request({
    url: `/report/unionfeaturepaper/subjectquestionlist/export`,
    method: "get",
    params: params,
  });
}
export function highscorequestionExport(params) {
  // 特征试卷-高分小题-导出
  return request({
    url: `/report/unionfeaturepaper/highscorequestion/export`,
    method: "post",
    data: params,
  });
}
export function onlineoverallExport(params) {
  // 上线情况-总体情况-导出
  return request({
    url: `/report/uniononlineanaly/onlineoverall/export`,
    method: "get",
    params: params,
  });
}
export function schooltotalonlineExport(params) {
  // 上线情况-各校总分上线情况-导出
  return request({
    url: `/report/uniononlineanaly/schooltotalonline/export`,
    method: "get",
    params: params,
  });
}
export function schoolsubjectonlineExport(params) {
  // 上线情况-各校单科上线情况-导出
  return request({
    url: `/report/uniononlineanaly/schoolsubjectonline/export`,
    method: "get",
    params: params,
  });
}
export function schoolallsubjectonlineExport(params) {
  // 上线情况-各校各学科上线情况-导出
  return request({
    url: `/report/uniononlineanaly/schoolallsubjectonline/export`,
    method: "get",
    params: params,
  });
}
export function gradescorelistExport(params) {
  // 学校分析-年级成绩榜-导出
  return request({
    url: `/report/unionschoolanaly/gradescorelist/export`,
    method: "post",
    data: params,
  });
}
export function exportAll(params) {
  //导出所有报告
  return request({
    url: `/report/statoneexamexport/union/exportAll`,
    method: `post`,
    data: params,
  });
}
export function onlineSettingExport(params) {
  // 导出联考上线设置
  return request({
    url: `/report/stat/exportstatonlinesetting`,
    method: "get",
    params: params,
  });
}
