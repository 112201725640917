<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">
          {{ $route.query.name }}-{{ subjectName }}分数段分布
        </div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>采用{{ dataType === 2 ? "赋分" : "原始分" }}数据源统计</span>
          </template>
          <a-radio-group
            v-if="
              $route.query.scoreType == 1 &&
              $route.query.ruleId > 0 &&
              ![1, 2, 3, 4, 8].includes(subjectId)
            "
            v-model="dataType"
            button-style="solid"
            size="small"
            @change="dataTypeChange"
          >
            <a-radio-button :value="1"> 原始分 </a-radio-button>
            <a-radio-button :value="2"> 赋分 </a-radio-button>
          </a-radio-group>
        </a-tooltip>
      </div>
      <div class="btns">
        <a-space>
          <a-button
            v-if="type === '1'"
            type="primary"
            ghost
            @click="downloadFile"
            >下载单科分数段分布表</a-button
          >
          <a-button
            v-if="type === '3'"
            type="primary"
            ghost
            @click="downloadFile"
            >下载单科详细分数段分布表</a-button
          >
        </a-space>
      </div>
    </div>
    <subjectList v-model="subjectId" @change="subjectChange" />
    <a-tabs v-model="type" type="card" @change="tabsChange">
      <a-tab-pane key="1" tab="单科分数段分布表"> </a-tab-pane>
      <a-tab-pane key="2" tab="单科分数段分布图"> </a-tab-pane>
      <a-tab-pane key="3" tab="单科详细分数段分布表"> </a-tab-pane>
      <a-tab-pane key="4" tab="频率分布正态检测图"> </a-tab-pane>
    </a-tabs>
    <a-table
      v-if="['1', '3'].includes(type)"
      bordered
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :scroll="{ x: 'max-content', y: tableX }"
    ></a-table>
    <template v-if="type === '2' && options">
      <div class="charts-box">
        <div ref="bar" class="charts"></div>
      </div>
    </template>
    <template v-if="type === '4' && options">
      <div class="line-chart-title">
        <div class="text">
          {{ subjectName }}频率分布正态检验图（单位：百分比）
        </div>
        <div class="data-tag">
          平均分：{{ averageScore }}，标准差：{{
            standardDeviation
          }}，总人数：{{ statTotal }}
        </div>
      </div>
      <div class="charts-box">
        <div ref="line" class="charts"></div>
      </div>
    </template>
    <no-data
      v-if="['2', '4'].includes(type) && !options"
      message="暂无数据"
    ></no-data>
  </div>
</template>

<script>
import * as echarts from "echarts";
import subjectList from "../../../components/subjectList.vue";
import { mapState } from "vuex";
import {
  subjectscoresegmentdistribution,
  subjectscoresegmentdistributionpic,
  subjectscoresegmentdistributiondetails,
  subjectfrequencydistributionnormaltestpic,
} from "@/core/api/academic/unionSheet";
import { getstatsubjectoption } from "@/core/api/academic/common";
import {
  subjectscoresegmentdistributionExport,
  subjectscoresegmentdistributiondetailsExport,
} from "@/core/api/academic/unionSheetExport";
export default {
  name: "",
  components: {
    subjectList,
  },
  data() {
    return {
      subjectName: "",
      tableData: [],
      type: "1",
      loading: false,
      btnLoading: false,
      dataType: 1,
      columns: [],
      subjectId: 1,
      tableX: window.innerHeight - 240,
      averageScore: null,
      standardDeviation: null,
      statTotal: null,
      columnsObj: {
        1: [
          {
            title: "分数段",
            width: 100,
            align: "center",
            dataIndex: "segment",
          },
          {
            title: "9.5",
            align: "center",
            key: "9.5",

            children: [
              { title: "段内", align: "center", dataIndex: "segmentScore" },
              { title: "分数段", align: "center", dataIndex: "total" },
            ],
          },
        ],
        3: [
          { title: "分数", align: "center", dataIndex: "score" },
          { title: "人数", align: "center", dataIndex: "total" },
          { title: "累积人数", align: "center", dataIndex: "statTotal" },
          { title: "百分比（%）", align: "center", dataIndex: "percent" },
          {
            title: "累积百分比（%）",
            align: "center",
            dataIndex: "totalPercent",
          },
        ],
      },
      options: null,
      subjectList: [],
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  created() {
    this.getSubject();
  },
  methods: {
    async getSubject() {
      const res = await getstatsubjectoption({ id: this.$route.query.id });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;

        let subject = this.subjectList.find(
          (item) => item.id == this.subjectId
        );
        this.subjectName = subject ? subject.name : "";
        this.tabsChange();
      }
    },
    async downloadFile() {
      let res;
      try {
        this.btnLoading = true;
        if (this.type === "1") {
          res = await subjectscoresegmentdistributionExport({
            subjectId: this.subjectId,
            id: this.$route.query.id,
            dataSource:
              this.scoreType == 1 && this.$route.query.ruleId > 0
                ? this.dataType
                : 1,
          });
        } else if (this.type === "3") {
          res = await subjectscoresegmentdistributiondetailsExport({
            id: this.$route.query.id,
            dataSource:
              this.scoreType == 1 && this.$route.query.ruleId > 0
                ? this.dataType
                : 1,
            subjectId: this.subjectId,
          });
        }
        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    subjectChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataType = 1;
      }
      this.tabsChange();
      let subject = this.subjectList.find((item) => item.id == this.subjectId);
      this.subjectName = subject ? subject.name : "";
    },
    dataTypeChange() {
      this.tabsChange();
    },
    async getKey1Data() {
      try {
        this.loading = true;
        const res = await subjectscoresegmentdistribution({
          subjectId: this.subjectId,
          id: this.$route.query.id,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });
        let cols = [];
        if (res.data.data.length) {
          cols = res.data.data[0].subSegments.map((item, index) => ({
            title: item.segment,
            align: "center",
            dataIndex: "subSegments" + index,
            children: [
              {
                title: "段内",
                align: "center",
                width: 50,
                dataIndex: "total" + index,
                customRender: (text, record) => record.subSegments[index].total,
              },
              {
                title: "累计",
                align: "center",
                width: 60,
                dataIndex: "accumulativeTotal" + index,
                customRender: (text, record) =>
                  record.subSegments[index].accumulativeTotal,
              },
            ],
          }));
        }
        this.columns = [
          {
            title: "分数段",
            width: 100,
            align: "center",
            dataIndex: "segment",
          },
          ...cols,
        ];
        this.tableData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getKey3Data() {
      try {
        this.loading = true;
        const res = await subjectscoresegmentdistributiondetails({
          id: this.$route.query.id,
          dataSource:
            this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          subjectId: this.subjectId,
        });
        this.tableData = res.data.data;
        this.columns = [
          {
            title: "分数",
            dataIndex: "segment",
            align: "center",
            width: 85,
          },
          {
            title: "人数",
            dataIndex: "total",
            align: "center",
            width: 85,
          },
          {
            title: "累积人数",
            dataIndex: "accumulativeTotal",
            width: 100,
            align: "center",
          },
          {
            title: "百分比（%）",
            dataIndex: "proportion",
            width: 150,
            align: "center",
            customRender: (text, record) => record.proportion,
          },
          {
            title: "累积百分比（%）",
            width: 150,
            dataIndex: "accumulativeProportion",
            align: "center",
            customRender: (text, record) => record.accumulativeProportion,
          },
        ];
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    tabsChange() {
      if (this.subjectId !== null) {
        const enmu = {
          1: this.getKey1Data,
          2: this.initBarCharts,
          3: this.getKey3Data,
          4: this.initLineCharts,
        };
        this.options = null;
        enmu[this.type]();
      }
    },
    async initBarCharts() {
      try {
        this.loading = true;
        const res = await subjectscoresegmentdistributionpic({
          id: this.$route.query.id,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          subjectId: this.subjectId,
        });
        if (res.data.data && res.data.data.length) {
          this.options = {
            xAxis: {
              type: "category",
              data: [...res.data.data.map((item) => item.segment)],
            },
            yAxis: {
              type: "value",
            },
            color: ["#2474ED"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#000",
                },
              },
            },
            legend: { itemGap: 30 },
            series: [
              {
                data: [...res.data.data.map((item) => item.total)],
                type: "bar",
                barMaxWidth: 40,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                      textStyle: {
                        fontSize: 9,
                      },
                    },
                  },
                },
              },
            ],
          };
          setTimeout(() => {
            let chart = echarts.init(this.$refs.bar);
            chart.setOption(this.options);
            setTimeout(() => {
              window.onresize = function () {
                chart.resize();
              };
            }, 200);
          }, 50);
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async initLineCharts() {
      try {
        this.loading = true;
        const res = await subjectfrequencydistributionnormaltestpic({
          id: this.$route.query.id,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          subjectId: this.subjectId,
        });
        this.averageScore = res.data.data.averageScore;
        this.standardDeviation = res.data.data.standardDeviation;
        this.statTotal = res.data.data.statTotal;

        if (res.data.data.segments && res.data.data.segments.length) {
          this.options = {
            xAxis: {
              type: "category",
              data: [...res.data.data.segments.map((item) => item.segment)],
            },
            yAxis: {
              type: "value",
            },
            color: ["#2474ED", "#FF6B00"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#000",
                },
              },
            },
            legend: { itemGap: 30, bottom: 0 },
            series: [
              {
                data: [
                  ...res.data.data.segments.map((item) => item.proportion),
                ],
                name: "分数频率分布",
                type: "line",
                smooth: false,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                      textStyle: {
                        fontSize: 9,
                      },
                    },
                  },
                },
              },
              {
                data: [
                  ...res.data.data.segments.map(
                    (item) => item.probabilityDensity
                  ),
                ],
                name: "参考线（正态分布）",
                type: "line",
                smooth: true,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                      textStyle: {
                        fontSize: 9,
                      },
                    },
                  },
                },
              },
            ],
          };
          setTimeout(() => {
            let chart = echarts.init(this.$refs.line);
            chart.setOption(this.options);
            setTimeout(() => {
              window.onresize = function () {
                chart.resize();
              };
            }, 200);
          }, 50);
        }
        this.loading = false;
      } catch {
        this.options = null;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 32px;

    .title-content {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }
}

::v-deep .ant-tabs-bar {
  margin: 0 0 0 0 !important;
}

.line-chart-title {
  margin: 18px 0;
  color: #0a1119;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.charts-box {
  width: 100%;

  .charts {
    width: 100%;
    height: 650px;
  }
}

::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 6px !important;
}

::v-deep .ant-table-row-cell-break-word {
  padding: 4px 6px !important;
}
</style>
